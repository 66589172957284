<template lang="pug">
	div(v-if="Array.isArray(logos) && logos.length > 0" class="row logo-row")
		div(v-for="(logo, index) in logos" :key="index" v-bind:class="'col l'+logo.cols+' m12 logo-col'")
			div(class="logo-wrapper")
				a(v-if="logo.target" v-bind:href="logo.target" target="_blank")
					img(v-bind:src="getImgUrl(logo.src)" v-bind:alt="logo.label" v-bind:title="logo.label" class="logo")
				img(v-else v-bind:src="getImgUrl(logo.src)" v-bind:alt="logo.label" v-bind:title="logo.label" class="logo")
</template>

<style scoped>
.logo-col {
	text-align: center;
}

.logo-wrapper {
    background-color: rgba(255, 255, 255, 0.7);
    color: #233642;
    padding: 3em 4em;
    font-size: 1.2em;
}

.logo {
	width: auto;
	height: auto;
	max-width: 80%;
}

@media screen and (max-width: 998px) {
	#page .row.logo-row {
		margin-bottom: 0px;
	}

	.logo-wrapper {
		margin-bottom: 25px;
	}
}
</style>

<script>
import UrlMixin from '@/mixins/UrlMixin';

export default {
	props: [ 'logos' ],
	mixins: [ UrlMixin ]
}
</script>