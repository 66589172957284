<template lang="pug">
	div(class="references")
		div(class="row")
			div(class="col s12")
				simplebox(content="Eine Liste ausgewählter Partner die bereits auf Dagobert vertrauen.")

		div(class="row")
			div(class="col s12")
				separator(text="Referenzen")

		logos(v-for="(cfg, index) in logoConfig" :key="index" v-bind:logos="cfg")

</template>

<script>
import Separator from '@/components/Separator';
import Simplebox from '@/components/Simplebox';
import Logos from '@/components/Logos';

export default {
	components: {
		'separator': Separator,
		'simplebox': Simplebox,
		'logos': Logos
	},
	data: function() {
		return {
			'logoConfig': [
				[
					{
						'src': 'external_logos/happiness-logo.png',
						'label': 'Happiness Festival',
						'cols': 4,
						'target': 'https://www.happiness-festival.de'
					},
					{
						'src': 'external_logos/feel-logo.png',
						'label': 'Feel Festival',
						'cols': 4,
						'target': 'https://feel-festival.de/'
					},
					{
						'src': 'external_logos/reload-logo.png',
						'label': 'Reload Festival',
						'cols': 4,
						'target': 'https://www.reload-festival.de/'
					}
				],
				[
					{
						'src': 'external_logos/ttf-logo.png',
						'label': 'Taubertal Festival',
						'cols': 4,
						'target': 'https://taubertal-festival.de/'
					},
					{
						'src': 'external_logos/maifeld-derby.png',
						'label': 'Maifeld Derby',
						'cols': 4,
						'target': 'http://www.maifeld-derby.de/'
					},
					{
						'src': 'external_logos/hookup-logo.png',
						'label': 'Hookup Festival',
						'cols': 4,
						'target': 'https://hookupfestival.de/'
					}
				],
				[
					{
						'src': 'external_logos/lunatic-festival.png',
						'label': 'Lunatic Festival',
						'cols': 4,
						'target': 'https://lunatic-festival.de/'
					},
					{
						'src': 'external_logos/nplus.png',
						'label': 'n plus sport',
						'cols': 4,
						'target': 'http://www.nplussport.de/'
					},
					{
						'src': 'external_logos/snntg.png',
						'label': 'SNNTG Festival',
						'cols': 4,
						'target': 'https://www.snntg.de/'
					}
				],
				[
					{
						'src': 'external_logos/bergfestival.png',
						'label': 'Bergfestival',
						'cols': 4,
						'target': 'https://www.berg-festival.com/'
					},
					{
						'src': 'external_logos/eastercross.png',
						'label': 'Easter Cross',
						'cols': 4,
						'target': 'https://www.easter-cross.de/'
					},
					{
						'src': 'external_logos/festivalohnebands.png',
						'label': 'Festival ohne Bands',
						'cols': 4,
						'target': 'https://festivalohnebands.de/'
					}
				],
				[
					{
						'src': 'external_logos/akasha.png',
						'label': 'Akasha Festival',
						'cols': 4,
						'target': 'https://www.akashafestival.de/'
					},
					{
						'src': 'external_logos/artlake.png',
						'label': 'Artlake Festival',
						'cols': 4,
						'target': 'https://artlake-festival.de/'
					},
					{
						'src': 'external_logos/rockamberg.png',
						'label': 'Rock am Berg Merkers',
						'cols': 4,
						'target': 'https://www.rockamberg-merkers.de/'
					}
				],
				[
					{
						'src': 'external_logos/apenair.png',
						'label': 'Apen Air Festival',
						'cols': 4,
						'target': 'http://apenair.de/'
					},
					{
						'src': 'external_logos/uebersee-festival.png',
						'label': 'Übersee Festival',
						'cols': 4,
						'target': 'https://ueberseefestival-bremen.de/'
					},
					{
						'src': 'external_logos/zimzum.png',
						'label': 'Zim Zum Festival',
						'cols': 4,
						'target': 'https://zimzumfestival.com/'
					}
				],
				[
					{
						'src': 'external_logos/imwizemann.png',
						'label': 'Im Wizemann',
						'cols': 4,
						'target': 'https://www.imwizemann.de/'
					},
					{
						'src': 'external_logos/brasswiesn.png',
						'label': 'Brass Wiesn Festival',
						'cols': 4,
						'target': 'https://www.brasswiesn.de/'
					},
					{
						'src': 'external_logos/puls.png',
						'label': 'Puls Open Air',
						'cols': 4,
						'target': 'https://pulsopenair.de/'
					}
				],
				[
					{
						'src': 'external_logos/tlpa.png',
						'label': 'The Living Proog Agency',
						'cols': 4,
						'target': 'https://tlpa.de/'
					},
					{
						'src': 'external_logos/rockambeckenrand.png',
						'label': 'Rock am Beckenrand',
						'cols': 4,
						'target': 'https://www.rockambeckenrand.de/'
					}
				]
			]
		}
	}
}
</script>